@import 'primereact/resources/themes/tailwind-light/theme.css';

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mobile-margin: 1.25rem;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .button-hover-color-transition {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  @media (max-width: 640px) {
    .container {
      margin-left: var(--mobile-margin);
      margin-right: var(--mobile-margin);
      max-width: calc(100vw - (var(--mobile-margin) * 2));
    }
  }
}

@layer base {
  html,
  body,
  #__next {
    @apply min-h-screen;
  }

  body {
    @apply overflow-x-hidden;
  }

  .account-input,
  .account-input:focus {
    @apply shadow-none ring-0 border-0 outline-none;
  }
  img {
    @apply inline-block;
  }
}

button:focus {
  outline: 0;
}

a:focus {
  outline: 0;
}

a,
input,
input::placeholder,
button,
td,
tr,
p,
pre,
b,
span,
div,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}
html,
body {
  max-width: 100%;
  /*overflow-x: hidden;*/
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.inter {
  font-family: 'Inter', sans-serif !important;
}

.react-datepicker__year-option:first-child {
  position: relative;
  color: white;
  background-color: #4d65af;
  border-color: #4d65af;
  height: 37px;
}

.react-datepicker__year-option:first-child:hover {
  color: black;
  background-color: #4d65af90;
  border-color: #4d65af90;
}

.react-datepicker__year-option:first-child::after {
  position: absolute;
  top: 5px;
  font-size: 20px;
  content: '\2191';
  left: 0;
  right: 0;
}

.react-datepicker__year-option:last-child {
  position: relative;
  color: white;
  background-color: #4d65af;
  border-color: #4d65af;
  height: 37px;
  margin-top: 10px;
}

.react-datepicker__year-option:last-child:hover {
  color: black;
  background-color: #4d65af90;
  border-color: #4d65af90;
}

.react-datepicker__year-option:last-child::after {
  position: absolute;
  content: '\2193';
  top: 6px;
  right: 0;
  left: 0;
  font-size: 20px;
}

.recharts-cartesian-axis-tick {
  cursor: pointer;
}

input[type='checkbox']:checked,
input[type='radio']:checked {
  color: #409f85;
  outline: none !important;
  box-shadow: none !important;
}

.clasic-input {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #9e9e9e !important;
  box-shadow: none !important;
}

.clasic-input-error {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #d94f63 !important;
  box-shadow: none !important;
}

.clasic-input:active,
.clasic-input:focus {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #409f85 !important;
  box-shadow: none;
  color: #409f85 !important;
}

.create-human-input {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #696969 !important;
}

.create-human-input-error,
.create-human-input-error:active,
.create-human-input-error:focus {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #d94f63 !important;
}

.create-human-input-warning,
.create-human-input-warning:active,
.create-human-input-warning:focus {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: #ffa35c !important;
}

.input,
.input:focus,
.input:active {
  outline: none !important;
  color: unset !important;
  border-color: #9e9e9e !important;
  box-shadow: none !important;
}

.search-input {
  transition: 300ms;
  outline: none !important;
  color: unset !important;
  border-color: black !important;
  box-shadow: none;
}

.search-input:focus,
.search-input:active {
  transition: 300ms;
  outline: #409f85 !important;
  color: unset !important;
  border-color: #409f85 !important;
  box-shadow: none;
}

textarea:focus,
textarea:active {
  outline: none !important;
  color: unset !important;
  border-color: #9e9e9e !important;
}

textarea {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  overflow: auto !important;
}

.Toastify__toast-theme--light.Toastify__toast--success {
  background-color: #f0f7f5;
  border: 1px solid #409f85;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
}

.Toastify__toast-theme--light.Toastify__toast--error {
  background-color: #fdf5f7;
  border: 1px solid #d94f63;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  /* width: 500px; */
  text-align: center;
}

.Toastify__toast-body > div:last-child {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media only screen and (max-width: 768px) {
  .Toastify__toast-theme--light.Toastify__toast--error {
    width: 300px;
    margin: 0 auto;
  }

  .Toastify__toast-container {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .Toastify__toast-body > div:last-child {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-icon {
  width: 100px !important;
  margin-right: -60px !important;
}

.recharts-cartesian-axis-tick > line {
  display: none !important;
}

.recharts-cartesian-axis > line {
  display: none !important;
}

.donutchart-innertext-label {
  font-size: 45px;
  line-height: 55px;
  fill: #409f85;
}

.tooltip-opacity {
  opacity: 1 !important;
}

@media only screen and (max-width: 768px) {
  .tooltip-opacity {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__react_component_tooltip {
    font-size: 10px !important;
  }
  .desktop-footer {
    display: none;
  }
  .desktop-auth {
    display: none;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  /* font-family: Arial, Helvetica, sans-serif; */
  padding: 5px;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
abbr[title] {
  text-decoration: none !important;
}

.cookie-input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 300ms;
}

.cookie-input-container input {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
  border: 1px solid #000000;
  transition: 300ms;
}

.cookie-input-container input:checked ~ .checkmark {
  background-color: white;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.cookie-input-container input:checked ~ .checkmark:after {
  display: block;
}

.cookie-input-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  background: #409f85;
}

select {
  -webkit-appearance: none;
}

input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

.small-caps {
  font-variant: all-small-caps;
}

.EZDrawer__container {
  visibility: visible !important;
}

@media only screen and (max-width: 768px) {
  .what {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 1em !important;
    transform: translateX(-50%) !important;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-chart {
    margin-top: -70px;
  }
  .world-chart-name-sections-mobile {
    margin-top: -70px;
  }
}

.death-country-tooltip {
  margin-top: -50px !important;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.custom-tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 15px;
  left: 30%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: #ffffff;
  color: #000000;
  font-size: 0.875rem;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  width: 81px;
  border: 1px solid #000000;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.custom-tooltip:hover::before,
.custom-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
}

.rdw-link-decorator-icon {
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0;
}

.wrapperClassName {
  margin-top: 30px;
  display: flex;
  border: 1px solid #949494;
  box-shadow: inset 0px 2px 3px -1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding-left: 11px;
  padding-right: 11px;
}

.toolbarClassName {
  display: none !important;
}

.rdw-link-decorator-wrapper > a {
  color: #409f85;
  text-decoration: underline;
}

.wrapperClassName {
  padding-bottom: 101px !important;
}

.mask {
  display: none !important;
  mask: none !important;
}

.highlighted {
  display: none !important;
}

svg[data-tip]:focus {
  outline: none;
}

[id^='react-select']:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

#appleid-signin {
  width: 100%;
  display: flex;
  justify-content: center;
}

#appleid-signin div {
  width: fit-content;
}
